import React, { ChangeEvent, useEffect } from 'react'

import * as S from './style'

type pageProps = {
  locationJobUnique: string[];
  setJobsLocation: Function;
  handleSelectLocation: Function;
  jobsLocation: string;
}

const SelectJobs = ({ locationJobUnique, handleSelectLocation, setJobsLocation, jobsLocation }: pageProps) => {
  useEffect(() => {
    if (jobsLocation) {
      setJobsLocation(jobsLocation)
    }
  }, [ jobsLocation, setJobsLocation ])

  return (
    <S.SelectFilter className='row'>
      <div className='col-12 select px-xl-2 mb-3'>
        <select value={jobsLocation} onChange={(evt: ChangeEvent<HTMLSelectElement>) => handleSelectLocation(evt)}>
          <option value=''>Localização</option>
          {locationJobUnique?.map((item: string, index: number) => {
            return (
              <option key={index} value={item} className='location'>
                {item}
              </option>)
          })
          }
        </select>
      </div>
    </S.SelectFilter>
  )
}

export default SelectJobs

import styled from 'styled-components'
import breakpoints from '../../../styles/breakpoints'
import { grayscale, white } from '../../../styles/colors'

export const SelectFilter = styled.div`

.select {

  select {
    font-size: 10px;
    color: ${grayscale['400']};
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    border: none;
    background-color: ${white};
    width: 100%;
    height: 48px;
    border-radius: 10px;
    padding-left: 20px;
    text-transform: capitalize;

    
    @media ${breakpoints.desktopLG} {
      font-size: 12px;
    }
  }
  
  &::after {
    content: "";
    border-color: transparent transparent #ff8700 #ff8700;
    border-style: solid;
    border-width: 2px;
    height: 9px;
    padding: 0;
    pointer-events: none;
    -webkit-transform: rotate(315deg);
    transform: rotate(315deg);
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    width: 9px;
    position: absolute;
    right: 20px;
    top: 15px;
    
  }
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: 12px;
    line-height: 15px;
  }
`
